<template>
	<modal
		:open="open"
		@close="$emit('close')"
		title="Transfer Patient"
	>
		<template slot="content">
			<list-patients :patients="patients" :isLoading="isLoading" :provider="provider" @change="selectPatient" />
		</template>
		<template slot="actions">
			<loading-line :visible="isLoading" />
			<mdc-button class="mdc-button__dialog modal-transfer__button modal-transfer__button--cancel" @click="$emit('close')">
				<span class="mdc-button__label">Cancel</span>
			</mdc-button>
			<mdc-button  class="mdc-button mdc-button--primary mdc-button--unelevated mdc-button__dialog" @click="transferProfile()" :disabled="selectedIdx < 0 || isLoading" >
				<span class="mdc-button__label">TRANSFER</span>
			</mdc-button>
		</template>
	</modal>
</template>

<script>
import ListPatients from '@/components/ListPatients'
import LoadingLine from '@/components/LoadingLine'
import LoadingSpinner from '@/components/LoadingSpinner'
import MdcButton from '@/components/MdcButton'
import Modal from '@/components/Modal'
import { CheckinStatus } from '@/lib/enums'

export default {
	name: 'ModalTransfer',
	components: {
		ListPatients,
		LoadingLine,
		LoadingSpinner,
		MdcButton,
		Modal,
	},
	props: {
		open: {
			type: Boolean,
			default: false,
		},
		checkin: {
			type: Object,
			default: () => ({}),
		},
		provider: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		isLoading: true,
		patients: [],
		selectedIdx: -1,
	}),
	computed: {
	},
	destroyed() {
		this.selectedIdx = -1
	},
	methods: {
		selectPatient(idx) {
			this.selectedIdx = idx
		},
		async transferProfile() {
			if (this.selectedIdx < 0) return

			const patient = this.patients[this.selectedIdx]
			console.log({ patient, }, this.checkin)
			const existing = this.selectedIdx !== (this.patients.length)
			console.log({ existing, }, this.selectedIdx, this.patients.length, )
			const msg = existing ? `to existing patient <strong>${patient.firstName} ${patient.lastName} (${this.$options.filters.date(patient.dob)})</strong>` : `as a new patient`
			this.$confirm(`This will transfer profile <strong>${this.checkin.firstName} ${this.checkin.lastName} (${this.$options.filters.date(this.checkin.dob)})</strong> ${msg} in your EMR.\n<br>Please confirm patient information is accurate.`, async () => {
				this.isLoading = true

				let transfer
				if (existing) {
					transfer = this.$store.dispatch(`${this.provider.key}/updatePatient`, { checkin: this.checkin, patient,})
				} else {
					transfer = this.$store.dispatch(`${this.provider.key}/addPatient`, { checkin: this.checkin, patient,})
				}

				transfer
				.then((res) => {
					this.checkin.status = CheckinStatus.Transferred
					this.checkin.transferredAt = (new Date()).getTime()
					this.$store.dispatch('checkin/updateCheckin', this.checkin)

					let noticeString = (res.notices && res.notices.length) ? `<li>${(res.notices || []).join(`</li><li>`)}</li>` : ``
					let errorString = (res.errors && res.errors.length) ? `<li>${(res.errors || []).join(`</li><li>`)}</li>` : ``

					this.$confirm(`<strong>${patient.firstName} ${patient.lastName}</strong> (${this.checkin.dob}) has been transferred to your EMR!<ul class="checkin_notices">${noticeString}</ul><ul class="checkin_errors">${errorString}</ul>`, () => {
						// this.$emit('close')
					}, {
						title: `Patient Transfer`,
						showCancel: false,
						acceptLabel: `DONE`,
					})

				})
				.catch((error) => {
					this.$confirm(`There was an error transferring this patient: <strong>${error.message || error}</strong>`, () => {}, {
						title: `Transfer Failed`,
						showCancel: false,
						acceptLabel: `Close`
					})
				})
				.finally(() => {
					this.isLoading = false
				})
			}, {
				title: `Transfer this patient?`,
				acceptLabel: `YES, TRANSFER PATIENT`,
			})
		}
	},
	watch: {
		open(val) {
			if (val) {
				if (!this.provider) {
					return this.$notice(`Your office needs to be connected to an EMR/EHR provider.`)
				}
				this.isLoading = true
				this.$store.dispatch(`${this.provider.key}/getPatients`, this.checkin)
					.then((res) => {
						this.patients = res
					})
					.catch((error) => {
						this.$notice(`ERROR: ${error.message || error}`)
					})
					.finally(() => {
						this.isLoading = false
					})

			}
		},
	},
}
</script>

<style scoped lang="scss">

</style>
