<template>
	<modal
		:open="open"
		@close="$emit('close')"
		:title="`Check In Code (${currentLocation.name})`"
		>
		<template slot="content">
			<checkin-code :data="codeString" v-if="codeString" />
		</template>
	</modal>
</template>

<script>
import CheckinCode from '@/components/CheckinCode'
import LoadingSpinner from '@/components/LoadingSpinner'
import Modal from '@/components/Modal'


export default {
	name: 'ModalCheckinCode',
	components: {
		CheckinCode,
		LoadingSpinner,
		Modal,
	},
	props: {
		open: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		currentLocation() {
			const me = this.$store.getters['user/me']
			return me.location
		},
		codeString() {
			const me = this.$store.getters['user/me']

			return `MEBKM:TITLE:${me.location.name}|${me.location.address};URL:https://getqp.app/o/${me.locationId};;`
			// return `MEBKM:TITLE:${me.location.name}|${me.location.address};URL:https://getqp.app/o/${me.locationId}/${me.terminal || 1};;`
		},
	},
}
</script>

<style scoped lang="scss">

</style>
