<template>
	<div class="status-chip" :class="`status-chip--${statusClass}`">
		{{ status }}
	</div>
</template>

<script>
export default {
	name: 'StatusChip',
	props: {
		status: '',
	},
	computed: {
		statusClass() {
			if (!this.status) return
			return this.status.toLowerCase()
		},
	},
}
</script>

<style scoped lang="scss">
.status-chip {
	background-color: modules.color_('background', 'light');
	border-radius: modules.$border-radius;
	display: inline-block;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 1px;
	text-align: center;
	text-transform: uppercase;
	padding: 4px 12px;

	&--new,
	&--New {
		background-color: modules.color_('alert', 'base');
		color: modules.color_('secondary','text');
	}

	&--accepted {
		background-color: modules.color_('alert', 'warn');
		color: modules.color_('secondary','text');
	}

	&--transferred {
		background-color: modules.color_('alert', 'success');
		color: modules.color_('secondary','text');
	}
}
</style>
