<template>
	<div class="view view--main main">
		<router-view class="main__header" name="header" />
		<router-view class="main__content" name="content" />
		<router-view class="main__footer" name="footer" />
		<modal @close="hideModal" :open="modalVisible" :title="modalTitle">
			<router-view class="main__modal" slot="content" name="modal" />
		</modal>
		<modal-checkin-status />
	</div>
</template>

<script>
import { FB } from '@/lib/fb'
import { ref, onDisconnect, onValue } from 'firebase/database'
import Modal from '@/components/Modal'
import ModalCheckinStatus from '@/components/ModalCheckinStatus'

export default {
	name: 'ViewMain',
	components: {
		Modal,
		ModalCheckinStatus,
	},
	computed: {
		modalVisible() {
			return this.$route.meta.modalVisible
		},
		modalTitle() {
			return this.$route.meta.modalTitle
		},
	},
	mounted() {
		this.$store.dispatch('transfer/watchOpen')

		const fb = FB.getInstance()
		onDisconnect(fb.refs.open).remove()
	},
	methods: {
		hideModal(clicked) {
			if (clicked) {
				this.$router.back()
			}
		},
	},
}
</script>

<style scoped lang="scss">
.main {
	background-color: modules.color_('background', 'light');
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	width: 100%;

	@include modules.media-query('phone') {
		margin-top: modules.$header-height;
		min-height: calc(100vh - #{modules.$header-height});
	}

	@media print {
		background-color: white;
	}
	.print & {
		background-color: white;
	}

	&__content {
		flex: 1;
		padding-bottom: modules.$footer-height !important;
	}

	&__header {
		@include modules.media-query('phone') {
			position: fixed;
			top: 0;
			width: 100%;
			z-index: 2;
		}
	}

	&__footer {
		bottom: 0;
		height: modules.$footer-height;
		position: fixed;
		width: 100%;
		z-index: 4;

		@include modules.media-query('phone') {
			background-color: modules.color_('background');
			border-top: 1px solid modules.color_('border');
		}
	}

	&::v-deep .partial {
		@include modules.gutter('padding-bottom');
		@include modules.gutter('padding-top');
		margin: 0 auto;
		max-width: 960px;
		width: 100%;

		&__header {
			@include modules.gutter('margin-bottom');
			align-items: flex-start;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			@include modules.media-query('phone') {
				@include modules.gutter('padding-left');
				@include modules.gutter('padding-right');
			}

			@media print {
				@include modules.gutter('padding-left');
				@include modules.gutter('padding-right');
			}
			.print & {
				@include modules.gutter('padding-left');
				@include modules.gutter('padding-right');
			}

			&-trailing,
			&-body {
				@include modules.media-query('phone') {
					flex: 1 0 100%;
				}
			}

			&-trailing {
				align-items: center;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				@include modules.media-query('phone') {
					padding-top: 0.5rem;
					flex-direction: row;
				}
				// text-align: right;
			}
		}

		&__title {
			@include modules.gutter('margin-bottom', 0.5);
			margin: 0;
			font-size: 30px;
			font-weight: 700;

			@include modules.media-query('phone') {
				font-size: 26px;
			}
		}

		&__subtitle {
			font-size: 14px;
			font-weight: 400;
			margin: 0;
			opacity: 0.8;
		}

		&__heading {
			font-size: 16px;
			font-weight: 300;
			text-transform: uppercase;

			span {
				display: block;
				font-weight: 500;
			}
		}
	}
}
</style>
