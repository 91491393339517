<template>
	<ul class="mdc-deprecated-list mdc-deprecated-list--two-line mdc-deprecated-list--avatar-list list-patients" :class="{'list-patients--loading': isLoading }" role="listbox">
		<li class="mdc-deprecated-list-item mdc-deprecated-list-item--disabled" v-if="!isLoading && !items.length">
			<span class="mdc-deprecated-list-item__text">
				<span class="mdc-deprecated-list-item__primary-text">
					...no patients found...
				</span>
				<span class="mdc-deprecated-list-item__secondary-text">
					&nbsp;
				</span>
			</span>
		</li>
		<li class="mdc-deprecated-list-item" role="option" v-for="(patient, key) in items" :key="key" :tabindex="(key + 1)">
			<span class="mdc-deprecated-list-item__ripple"></span>
			<span class="mdc-deprecated-list-item__graphic">
				<div class="list-patients__photo" :data-initials="`${patient.firstName} ${patient.lastName}` | initials"></div>
			</span>
			<span class="mdc-deprecated-list-item__text">
				<span class="mdc-deprecated-list-item__primary-text">
					{{ patient.lastName }}, {{ patient.firstName }} {{ patient.middleName }}
				</span>
				<span class="mdc-deprecated-list-item__secondary-text">
					{{ patient.dob | date }}
					({{ patient.gender }})
				</span>
			</span>
		</li>
		<li role="separator" class="mdc-deprecated-list-divider"></li>
		<li class="mdc-deprecated-list-item" role="option" :tabindex="0">
			<span class="mdc-deprecated-list-item__ripple"></span>
			<span class="mdc-deprecated-list-item__graphic">
				<i class="material-icons">add</i>
			</span>
			<span class="mdc-deprecated-list-item__text">
				<span class="mdc-deprecated-list-item__primary-text">
					New Patient
				</span>
				<span class="mdc-deprecated-list-item__secondary-text">
					Add as a new patient
				</span>
			</span>

		</li>
	</ul>
</template>

<script>
import { MDCList } from '@material/list'
import fieldMap from '@/lib/filters/fieldMap'
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
	name: 'ListPatients',
	components: {
		LoadingSpinner,
	},
	props: {
		isLoading: {
			type: Boolean,
			default: true,
		},
		patients: {
			type: Array,
			default: () => ([]),
		},
		provider: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		list: undefined,
	}),
	computed: {
		items() {
			if (!this.patients) return []
			if (!this.provider) return this.patients
			return (this.patients).map((p) => fieldMap(p, this.provider.key, true))
		},
	},
	mounted() {
		this.list = new MDCList(this.$el)
		this.list.singleSelection = true
		this.list.listen('MDCList:action', () => {
			this.$emit('change', this.list?.selectedIndex)
		})
	},
	destroyed() {
		this.list.selectedIndex = -1
		console.log('destroyed')
	},

}
</script>

<style scoped lang="scss">
@use '@material/list/mdc-list';

.list-patients {
	position: relative;
	height: 100%;
	overflow-y: auto;
	flex: 1;

	&--loading {
		opacity: 0.7;
		pointer-events: none;
	}

	&__loading {
		background: rgba(255, 255, 255, 0.6);
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		z-index: 22;
	}

	&__photo {
		background-color: darken(modules.color_('background', 'light'), 4);
		background-position: center center;
		background-size: cover;
		align-items: center;
		border-radius: 50%;
		color: rgba(0, 0, 0, 0);
		display: flex;
		flex-direction: column;
		height: 40px;
		overflow: hidden;
		justify-content: center;
		width: 40px;

		@include modules.media-query('phone') {
			height: 70px;
			width: 70px;
		}

		&:empty {
			// &:before {
			// 	color: modules.color_('text', 'medium');
			// 	content: 'person';
			// 	font-family: 'Material Icons';
			// 	font-feature-settings: 'liga';
			// 	font-size: 48px;
			// }

			&:after {
				color: modules.color_('text', 'medium');
				content: attr(data-initials);
				font-size: 12px;
				font-weight: 600;
				letter-spacing: 2px;
			}
		}
	}
}
</style>
