<template>
	<div class="user-nav" :class="{'user-nav--open': isToggled }">
		<button class="user-nav__button mdc-icon-button material-icons" @click="toggle()">
			<span class="mdc-button__ripple"></span>
			menu
		</button>
		<nav class="user-nav__items">
			<router-link
				class="user-nav__item mdc-button mdc-button--primary mdc-button--unelevated"
				:to="{ name: 'main' }"
			>
				<i class="material-icons mdc-button__icon">list_alt</i>
				<span class="user-nav__item-title mdc-button__label">Check-Ins</span>
			</router-link>
			<router-link
				class="user-nav__item mdc-button mdc-button--primary mdc-button--unelevated"
				active-class="user-nav__item--active"
				:key="`internal_${key}`"
				:to="item.link"
				v-for="(item, key) in navItems"
			>
				<i class="material-icons mdc-button__icon" v-html="item.icon"></i>
				<span class="user-nav__item-title mdc-button__label">{{ item.title }}</span>
			</router-link>
			<span class="user-nav__item mdc-menu-surface--anchor">
				<button class="mdc-button mdc-button--primary mdc-button--unelevated" id="menu-button" @click="menu.open = !menu.open">
					<i class="material-icons mdc-button__icon">arrow_drop_down</i>
					<span class="mdc-button__label">{{ me.firstName }} {{ me.lastName }}</span>
				</button>
				<div class="mdc-menu mdc-menu-surface">
					<ul class="mdc-deprecated-list" role="menu" aria-hidden="true" aria-orientation="vertical">
						<router-link :to="{name: 'account'}" tag="li" class="mdc-deprecated-list-item" role="menuitem">
							<span class="mdc-deprecated-list-item__ripple"></span>
							<span class="mdc-deprecated-list-item__graphic"><i class="material-icons">person</i></span>
							<span class="mdc-deprecated-list-item__text">Account</span>
						</router-link>
						<li class="mdc-deprecated-list-item" role="menuitem" @click="logout()">
							<span class="mdc-deprecated-list-item__ripple"></span>
							<span class="mdc-deprecated-list-item__graphic"><i class="material-icons">power_settings_new</i></span>
							<span class="mdc-deprecated-list-item__text">Logout</span>
						</li>
					</ul>
				</div>
			</span>
		</nav>
	</div>
</template>

<script>
import { UserLevel } from '@/lib/enums'
import { MDCMenu } from '@material/menu'

export default {
	name: 'UserNav',
	data: () => ({
		menu: undefined,
	}),
	computed: {
		me() {
			return this.$store.getters['user/me']
		},
		navItems() {
			let items = []
			switch (this.me.permissions) {
				case UserLevel.Manager:
					items = [
						...items,
						...this.$store.getters['navigation/menu'](UserLevel.Manager),
					]
					break
				case UserLevel.Client:
					items = [
						...items,
						...this.$store.getters['navigation/menu'](UserLevel.Client),
						...this.$store.getters['navigation/menu'](UserLevel.Manager),
					]
					break
				case UserLevel.Internal:
					items = [
						...items,
						...this.$store.getters['navigation/menu'](UserLevel.Internal),
						...this.$store.getters['navigation/menu'](UserLevel.Client),
						...this.$store.getters['navigation/menu'](UserLevel.Manager),
					]
					break
				case UserLevel.Admin:
					items = [
						...items,
						...this.$store.getters['navigation/menu'](UserLevel.Internal),
						...this.$store.getters['navigation/menu'](UserLevel.Client),
						...this.$store.getters['navigation/menu'](UserLevel.Manager),
						...this.$store.getters['navigation/menu'](UserLevel.Admin),
					]
					break
			}

			return items
		},
		isToggled() {
			return this.$store.getters['navigation/isToggled']
		},
	},
	mounted() {
		this.menu = new MDCMenu(this.$el.querySelector('.mdc-menu'))
	},
	methods: {
		logout() {
			this.$store.dispatch('user/logout')
			this.$router.push({ name: 'login', })
		},
		toggle() {
			this.$store.dispatch('navigation/menuToggle')
		},
	},
}
</script>

<style scoped lang="scss">
@use '@material/icon-button/styles';
@use "@material/list/mdc-list";
@use "@material/menu-surface/mdc-menu-surface";
@use "@material/menu/mdc-menu";

.user-nav {
	$nav: &;

	&__button {
		display: none;

		@include modules.media-query('phone') {
			display: inline-block;
		}
	}

	&__items {
		@include modules.media-query('phone') {
			@include modules.gutter('padding');
			@include modules.gutter('padding-top', 0);
			background-color: modules.color_('primary');
			display: flex;
			flex-direction: column;
			opacity: 0;
			position: absolute;
			right: 0;
			transform: translateX(100%);
			transition: all 250ms modules.$ease-standard;
			pointer-events: none;

			#{$nav}--open & {
				opacity: 1;
				pointer-events: any;
				transform: translateX(0);
			}
		}
	}

	#{$nav}__item {
		@include modules.media-query('phone') {
			@include modules.gutter('padding');
			@include modules.gutter('margin-top');
			font-size: 1.1em;

			.mdc-button__icon {
				font-size: 1.2em;
			}
		}

		&.router-link-exact-active {
			font-weight: bold;
		}
	}

	.mdc-menu-surface {
		top: 150% !important;
		left: auto !important;
		right: 0 !important;
	}

	.mdc-deprecated-list {
		@include modules.fontSize(14px);

		.material-icons {
			font-size: 20px;
		}
	}

}
</style>
