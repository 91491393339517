<template>
	<div class="agreements-fields">
		<div
			class="agreements-field" :id="`agreements_field_${key}`"
			:key="key"
			v-for="(agreement, key) in agreements"
			>
			<div class="agreements-field__title">
				{{ agreement.title }}<br>
				<small>(modified: {{ agreement.dateModified | date('LLL dd, yyyy') }})</small>
			</div>
			<div class="agreements-field__content only-print" v-html="agreement.content"></div>
			<div class="agreements-field__signature">
				<img :src="`data:image/jpg;base64,${agreement.signature}`" v-if="agreement.signature" /><br>
				<span class="agreements-field__date" v-if="agreement.signature">Signed: {{ agreement.timestamp | date('LLL dd, yyyy hh:mm:ss a') }}</span><br>
				<a class="mdc-button mdc-button--unelevated" @click.prevent.stop="downloadPdf(agreement, key)">
					<i class="material-icons mdc-button__icon" aria-hidden="true">get_app</i><span class="mdc-button__label">Download</span>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import { jsPDF } from 'jspdf'
import { format } from 'date-fns'

export default {
name: 'AgreementsFields',
	props: {
		agreements: Array,
		checkin: Object,
	},
	methods: {
		downloadPdf(agreement, idx) {
			const doc = new jsPDF()

			doc.setFont('courier')
			doc.setFontSize(16)
			doc.text(agreement.title, 10, 10);

			doc.setFontSize(10)
			doc.text(`modified: ${agreement.dateModified}`, 10, 20)

			doc.setFontSize(11)
			let newY = addWrappedText({
				text: agreement.content,
				textWidth: 180,
				doc,
				xPosition: 10,
				initialYPosition: 40,
			})
			// doc.text(doc.splitTextToSize(agreement.content.split('\n'), 60), 10, 40, {
			// 	maxWidth: 180,
			// })

			// doc.addPage()
			doc.setFontSize(11)
			doc.addImage(`data:image/jpg;base64,${agreement.signature}`, 'JPEG', 10, newY + 10, 66, 25)
			doc.setFontSize(14)
			let signed = this.checkin.firstName +' '+ this.checkin.lastName
			if (this.checkin.responsibleFirstName) {
				signed = this.checkin.responsibleFirstName +' '+ this.checkin.responsibleLastName
			}
			doc.text(`${signed}`, 10, newY + 40)
			doc.setFontSize(10)
			doc.text(`(${format(agreement.timestamp, 'LLL dd, yyyy hh:mm:ss a')})`, 10, newY + 46)

			let filename = `${this.checkin.lastName}, ${this.checkin.firstName} - ${agreement.title}.pdf`
			doc.save(filename)
		},
	},
}

function addWrappedText(
	{text, textWidth, doc, fontSize = 10, fontType = 'normal', lineSpacing = 7, xPosition = 10, initialYPosition = 10, pageWrapInitialYPosition = 10}) {
	// doc.setFontType(fontType);
	doc.setFontSize(fontSize);
	var textLines = doc.splitTextToSize(text, textWidth); // Split the text into lines
	var pageHeight = doc.internal.pageSize.height;        // Get page height, we'll use this for auto-paging. TRANSLATE this line if using units other than `pt`
	var cursorY = initialYPosition;

	textLines.forEach((lineText) => {
		if (cursorY > pageHeight) { // Auto-paging
			doc.addPage();
			cursorY = pageWrapInitialYPosition;
		}
		doc.text(lineText, xPosition, cursorY);
		cursorY += lineSpacing;
	})
	return cursorY
}
</script>

<style scoped lang="scss">
.agreements-field {
	$field: &;
	@include modules.gutter('padding');
	align-items: flex-start;
	border-bottom: 1px solid modules.color_('border');
	display: flex;
	flex-wrap: wrap;
	// display: grid;
	// grid-template-columns: 1fr 2fr;
	justify-content: space-between;

	@media print {
		margin-top: 1rem;
		page-break-inside: avoid;
		page-break-after: always;

		.mdc-button {
			display: none;
		}
	}


	@include modules.media-query('phone') {
		grid-template-columns: 1fr;
	}

	img {
		max-width: 330px;
	}

	&__title {
		color: modules.color_('text', 'medium');
		font-weight: 600;
		flex: 1;

		small {
			font-weight: normal;
		}
	}

	&__signature {
		@include modules.gutter('padding-left');
		flex: 2;
	}

	&__date {
		font-size: 0.9em;
		text-transform: uppercase;
	}

	&__content {
		@include modules.gutter('margin-bottom');
		@include modules.gutter('margin-top');
		flex: 1 0 100%;
		font-size: 0.8em;
		white-space: pre-wrap
	}
}
</style>
