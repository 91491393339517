<template>
	<div class="partial partial--checkin checkin">
		<nav class="checkin__nav">
		</nav>

		<div class="partial__header">
			<div class="partial__header-body">
				<h1 class="partial__title">
					{{ checkin.lastName }}, {{ checkin.firstName }} {{ checkin.middleName }}
				</h1>
				<h2 class="partial__subtitle">
					<span :title="checkin.dob | date">{{ checkin.dob | age }} years old</span> |
					Check-In: {{ checkin.createdAt | date('hh:mm a')}} |
					<span :title="checkin.checkinUpdated | date('MM/dd/yyyy hh:mma')">Profile Updated: {{ checkin.profileUpdated | timeAgo(true, true) }}</span>
				</h2>
			</div>
			<div class="partial__header-trailing">
				<status-chip :status="checkin.status | enums('CheckinStatus', true)" />
				<h3 class="partial__heading" v-if="checkin.curbside && (checkin.curbside || checkin.curbside.number)">
					Curbside
					<span v-if="checkin.curbside.number">#{{ checkin.curbside.number }}</span>
					<span> {{ checkin.curbside }}</span>
				</h3>
			</div>
		</div>

		<div class="checkin__body">
			<div class="checkin__info">
				<card>
					<h3 class="card__title">Chief Complaints</h3>
					<div class="card__fields">
						<p class="checkin__complaint" v-html="checkin.complaint" />
					</div>
				</card>

				<card>
					<h3 class="card__title">Profile Information</h3>
					<div class="card__fields" id="checkin_checkin">
						<checkin-fields :checkin="checkin" :fields="['firstName', 'lastName', 'middleName','dob', 'gender', 'height', 'weight', 'bloodType']" />
					</div>
				</card>

				<card>
					<h3 class="card__title">Contact Information</h3>
					<div class="card__fields" id="checkin_contact">
						<checkin-fields :checkin="checkin" :fields="['email', 'phone', 'phone2', 'ssn', 'marital', 'employment', 'employer', ]" />
					</div>
				</card>

				<card>
					<h3 class="card__title">Address Information</h3>
					<div class="card__fields">
						<checkin-fields :checkin="checkin" :fields="['street', 'street2', 'city', 'state', 'zip', 'country', 'licensePhoto', ]" />
					</div>
				</card>

				<card>
					<h3 class="card__title">
						Insurance Information
					</h3>
					<div class="card__fields" id="checkin_insurance">
						<checkin-fields :checkin="checkin" :fields="insuranceFields" />
					</div>
				</card>

				<card>
					<h3 class="card__title">COVID-19 Screening</h3>
					<p class="card__subtitle">Questionnaire Responses</p>
					<div class="card__fields">
						<checkin-fields :checkin="checkin" :fields="covidScreen" :source="covidQuestions" />
					</div>
					<h3 class="card__title">COVID-19 History</h3>
					<div class="card__fields">
						<checkin-fields :checkin="checkin" :fields="covidFields" />
					</div>
				</card>

				<card>
					<h3 class="card__title">
						Emergency Contact
					</h3>
					<div class="card__fields">
						<checkin-fields :checkin="checkin" :fields="['emergencyFirstName', 'emergencyLastName', 'emergencyPhone', 'emergencyRelationship']" />
					</div>
				</card>

				<card>
					<h3 class="card__title">
						Responsible Party
					</h3>
					<div class="card__fields">
						<checkin-fields :checkin="checkin" :fields="responsiblePartyFields" />
					</div>
				</card>

				<card>
					<h3 class="card__title">
						Primary Care Provider
					</h3>
					<div class="card__fields">
						<checkin-fields :checkin="checkin" :fields="primaryCareFields" />
					</div>
				</card>

				<card>
					<h3 class="card__title">
						Preferred Pharmacy
					</h3>
					<div class="card__fields">
						<checkin-fields :checkin="checkin" :fields="['pharmacyName', 'pharmacyPhone', 'pharmacyStreet','pharmacyStreet2','pharmacyCity','pharmacyState', 'pharmacyZip', 'pharmacyCountry']" />
					</div>
				</card>

				<card>
					<h3 class="card__title" id="checkin_history">
						Medical History
					</h3>
					<div class="card__fields">
						<checkin-fields :checkin="checkin" :fields="['historyMedical']" />
					</div>
				</card>

				<card>
					<h3 class="card__title">
						Surgical History
					</h3>
					<div class="card__fields">
						<checkin-fields-list field="historySurgeries" :checkin="checkin" />
					</div>
				</card>

				<card>
					<h3 class="card__title">
						Family Medical History
					</h3>
					<div class="card__fields">
						<checkin-fields-list field="historyFamily" :checkin="checkin" />
					</div>
				</card>

				<card>
					<h3 class="card__title">
						Current Medications
					</h3>
					<div class="card__fields">
						<checkin-fields-list field="historyMedications" :checkin="checkin" />
					</div>
				</card>

				<card>
					<h3 class="card__title">
						Drug Allergies
					</h3>
					<div class="card__fields">
						<checkin-fields-list field="historyDrugAllergies" :checkin="checkin" />
					</div>
				</card>

				<card>
					<h3 class="card__title">
						Environmental Allergies
					</h3>
					<div class="card__fields">
						<checkin-fields-list field="historyAllergies" :checkin="checkin" />
					</div>
				</card>

				<card>
					<h3 class="card__title">
						Social History
					</h3>
					<div class="card__fields">
						<checkin-fields :checkin="checkin" :fields="['socialHistoryAlcohol', 'socialHistoryAlcoholAmount', 'socialHistoryTobacco', 'socialHistoryTobaccoType']" />
					</div>
				</card>

				<card>
					<h3 class="card__title">
						Legal Agreements
					</h3>
					<div class="card__fields">
						<agreements-fields :agreements="agreementsList" :checkin="checkin" />
					</div>
				</card>
			</div>

			<div class="checkin__meta">
				<card class="checkin__meta-info">
					<div class="card__fields">
						<div class="checkin__photo-wrapper hover" :class="{'checkin__photo-wrapper--empty':!checkin.photo}">
							<img :src="`data:image/jpeg;base64,${checkin.photo}`" :alt="checkin.firstName" class="checkin__photo" v-if="checkin.photo" />
							<div class="checkin__photo" :data-initials="`${checkin.firstName} ${checkin.lastName}`" v-if="!checkin.photo"></div>
							<a :href="`data:image/jpg;base64,${checkin.photo}`" :download="`${checkin.firstName}_${checkin.lastName}-photo.jpg`" class="mdc-button mdc-button--unelevated mdc-button--full mdc-button--primary hover" v-if="checkin.photo">
								<span class="mdc-button__ripple"></span>
								<i class="material-icons mdc-button__icon" aria-hidden="true">get_app</i>
								<span class="mdc-button__label">Download Photo</span>
							</a>
						</div>

						<p class="checkin__meta-item">
							<strong>{{ checkin.lastName }}, {{ checkin.firstName }} {{ checkin.middleName }}</strong>
							<span>({{ checkin.gender }})</span>
						</p>
						<p class="checkin__meta-item">
							<strong>DOB</strong>
							<span>{{ checkin.dob }}</span>
						</p>
						<hr />

						<p class="checkin__meta-item" v-if="checkin.acceptedAt" :title="checkin.acceptedAt | date('MM/dd/yyyy hh:mma')">
							<strong>Accepted</strong>
							<span>{{ checkin.acceptedAt | timeAgo }}</span>
						</p>

						<p class="checkin__meta-item" v-if="checkin.transferredAt" :title="checkin.transferredAt | date('MM/dd/yyyy hh:mma')">
							<strong>Transferred</strong>
							<span>{{ checkin.transferredAt | timeAgo }}</span>
						</p>

						<div class="checkin__actions" id="checkin_actions">
							<mdc-button
								class="checkin__action mdc-button--outline mdc-button--full"
								@click="messageToggle = true"
								>
								<i class="material-icons mdc-button__icon" aria-hidden="true">message</i>
								<span class="mdc-button__label">Message</span>
							</mdc-button>

							<mdc-button
								class="checkin__action mdc-button--unelevated mdc-button--full mdc-button--success"
								@click="acceptCheckin(checkin)"
								v-if="checkin.status === checkinStatus.New"
								>
								<i class="material-icons mdc-button__icon" aria-hidden="true">done</i>
								<span class="mdc-button__label">Accept</span>
							</mdc-button>

							<mdc-button
								class="checkin__action mdc-button--unelevated mdc-button--full mdc-button--warn"
								@click="transferCheckin(checkin)"
								v-if="checkin.status === checkinStatus.Accepted || checkin.status === checkinStatus.Transferred"
								>
								<i class="material-icons mdc-button__icon" aria-hidden="true">transfer_within_a_station</i>
								<span class="mdc-button__label">Transfer</span>
							</mdc-button>

							<mdc-button
								class="checkin__action mdc-button--unelevated mdc-button--full"
								@click="print()"
								>
								<i class="material-icons mdc-button__icon" aria-hidden="true">print</i>
								<span class="mdc-button__label">Print</span>
							</mdc-button>

							<mdc-button
								class="checkin__action mdc-button--unelevated mdc-button--full mdc-button--error"
								@click="deleteCheckin(checkin)"
								>
								<i class="material-icons mdc-button__icon" aria-hidden="true">delete</i>
								<span class="mdc-button__label">Delete</span>
							</mdc-button>
						</div>
					</div>

				</card>
			</div>
		</div>

		<modal-transfer
			:open="transferOpen"
			@close="transferOpen = false"
			:checkin="checkin"
			:provider="provider"
		/>

	</div>
</template>

<script>
import AgreementsFields from '@/components/AgreementsFields'
import Card from '@/components/Card'
import CheckinFields from '@/components/CheckinFields'
import CheckinFieldsList from '@/components/CheckinFieldsList'
import LoadingSpinner from '@/components/LoadingSpinner'
import MdcButton from '@/components/MdcButton'
import ModalTransfer from '@/components/ModalTransfer'
import StatusChip from '@/components/StatusChip'
import { covidFields } from '@/lib/utils'
import { CheckinStatus } from '@/lib/enums'

export default {
	name: 'PartialCheckin',
	components: {
		AgreementsFields,
		Card,
		CheckinFields,
		CheckinFieldsList,
		LoadingSpinner,
		ModalTransfer,
		MdcButton,
		StatusChip,
	},
	data: () => ({
		covidQuestions: covidFields,
		transferOpen: false,
		// CheckinStatus: CheckinStatus,
	}),
	computed: {
		provider() {
			return this.$store.getters['user/provider']
		},
		checkinStatus() {
			return CheckinStatus
		},
		checkin() {
			return this.$store.getters['checkin/checkin']
		},
		insuranceFields() {
			if (this.checkin.insuranceUnavailable) {
				return ['insuranceUnavailable']
			}

			return ['insuranceProvider', 'insuranceType', 'insuranceGroup', 'insuranceFirstName',
		'insuranceLastName','insuranceDob','insuranceGender','insuranceNumber','insuranceDate','insurancePhone','insurancePhotoFront','insurancePhotoBack']
		},
		primaryCareFields() {
			if (this.checkin.primaryUnavailable) {
				return ['primaryUnavailable']
			}

			return ['primaryName', 'primaryPhone', 'primaryCity', 'primaryState']
		},
		responsiblePartyFields() {
			if (this.checkin.responsibleParty) {
				return ['responsibleParty']
			}

			return ['responsibleFirstName', 'responsibleMiddleName', 'responsibleLastName', 'responsibleDob', 'responsibleSsn', 'responsibleStreet', 'responsibleStreet2', 'responsibleState', 'responsibleCity', 'responsibleZip', 'responsibleCountry', 'responsibleEmail', 'responsiblePhone', 'responsiblePhone2', 'responsibleRelationship']
		},
		covidScreen() {
			let list = []
			for (const field of this.covidQuestions) {
				list.push(field.name)
			}
			return list
		},
		covidFields() {
			let fields = ['covidTest',]
			if (this.checkin.covidTest) {
				fields = [...fields, ...['covidTestDate','covidTestType', 'covidTestResult', ]]
			}
			fields.push('covidVaccine')
			if (this.checkin.covidVaccine) {
				fields = [...fields, ...['covidVaccineBrand', 'covidVaccineDate1', 'covidVaccineDate2', 'covidVaccineDate3', 'covidCardFront', 'covidCardBack']]
			}
			return fields
		},
		agreementsList() {
			const list = []

			this.checkin?.agreements?.forEach((agreement) => {
				list.push({
					...agreement,
					signature: agreement.signed ? this.checkin.signature : null,
				})
			})

			return list
		},
	},
	mounted() {
		const { id } = this.$route.params
		this.$store.dispatch('checkin/loadCheckin', id)
	},
	destroyed() {
		this.$store.dispatch('checkin/unloadCheckin')
	},
	methods: {
		acceptCheckin(checkin) {
			this.$store.dispatch('checkin/acceptCheckin', checkin)
				.then((res) => {
					this.$notice(`Check-In Accepted!`)
				})
				.catch((error) => {
					this.$notice(`ERROR: ${error.message || error}`)
				})
				.finally(() => {

				})
		},
		deleteCheckin(checkin) {
			this.$confirm(`Are you sure you want to DELETE the check-in for ${checkin.firstName} ${checkin.lastName}?`, () => {
				this.$store.dispatch('checkin/deleteCheckin', checkin)
					.then(() => {
						this.$router.push({ name: 'main' })
					})
					.catch((error) => {
						this.$notice(`ERROR: ${error.message || error}`)
					})
					.finally(() => {

					})
			}, {
				title: `Delete "${checkin.firstName} ${checkin.lastName}"?`,
				acceptLabel: `Yes, Delete`,
			})
		},
		transferCheckin(checkin) {
			this.transferOpen = true
		},
		print() {

		},
		bgImg(src) {
			if (!src) return ''
			return `url(data:image/jpg;base64,${src})`
		},
	},
	metaInfo() {
		return {
			title: `${this.checkin.firstName} ${this.checkin.lastName}`,
		}
	},
}
</script>

<style scoped lang="scss">
.checkin {
	@include modules.media-query('phone') {
		margin-top: modules.$header-height !important;
		scroll-padding-top: modules.$header-height * 2;
	}

	&__checkin-photo {
		max-width: 100px;
	}

	@media print {
		.mdc-button {
			display: none;

			&__label {
				display: none;
			}
		}
	}

	&__loading {
		background-color: rgba(modules.color_('background', 'light'), 0.2);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 4;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__body {
		@include modules.gutter('grid-column-gap');
		display: grid;
		grid-template-columns: 2.5fr 1fr;
		position: relative;

		@include modules.media-query('tablet') {
			grid-template-columns: 1fr;
		}

		@include modules.media-query('phone') {
			grid-template-columns: 1fr;
		}

		@media print {
			grid-template-columns: 1fr;
		}
		.print & {
			grid-template-columns: 1fr;
		}
	}

	&__meta {
		color: modules.color_('text', 'medium');
		font-size: 15px;

		@include modules.media-query('phone') {
			padding-bottom: modules.$footer-height;
			z-index: 1;
		}

		&-item {
			display: grid;
			grid-column-gap: 0.25em;
			grid-template-columns: 0.85fr auto;
		}

		&-info {
			@include modules.gutter('top');
			position: sticky;

			@media only screen and (max-height: 800px) {
				@include modules.gutter('top', 0);
				position: relative;
			}

			@media print {
				top: 1em;
				position: relative;
			}
			.print & {
				top: 1em;
				position: relative;
			}

			@include modules.media-query('phone') {
				margin-bottom: 4rem;
			}
		}

		@include modules.media-query('phone') {
			// grid-row-start: 2;
		}
	}

	&__info {
		@include modules.media-query('phone') {
			max-width: 100vw;
		}
	}

	&__photo {
		@include modules.gutter('margin-bottom', 0.25);
		// @include modules.gutter('margin-top');
		align-items: center;
		background-color: darken(modules.color_('background', 'light'), 4);
		background-size: cover;
		background-position: center top;
		border-radius: modules.$border-radius;
		color: rgba(0,0,0,0);
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 100%;
		flex: 1;
		// height: 250px;
		overflow: hidden;
		word-break: break-all;

		&:empty {
			&:before {
				color: modules.color_('text', 'medium');
				content: 'person';
				font-family: 'Material Icons';
				font-feature-settings: 'liga';
				font-size: 68px;
			}

			&:after {
				color: modules.color_('text', 'medium');
				content: attr(data-initials);
				font-size: 16px;
				font-weight: 600;
				letter-spacing: 2px;
			}
		}

		&-wrapper {
			@include modules.gutter('padding-top');
			display: flex;
			flex-direction: column;
			position: relative;

			&--empty {
				height: 250px;
			}

			.mdc-button {
				@include modules.gutter('bottom');
				left: 50%;
				position: absolute;
				transform: translateX(-50%);
				width: 90%;
				z-index: 9;
			}
		}

		@media print {
			max-width: 450px !important;
		}
		.print & {
			max-width: 450px !important;
		}

	}

	hr {
		border: 0;
		border-top: 1px solid modules.color_('border');
		height: 0px;
		margin-top: 1rem;

	}

	&__actions {
		border-top: 1px solid transparent;

		@media print {
			display: none;
		}
		.print & {
			display: none;
		}

		@include modules.media-query('phone') {
			@include modules.gutter('padding-left');
			@include modules.gutter('padding-right');
			background-color: modules.color_('background');
			border-top-color: modules.color_('border');
			bottom: modules.$footer-height;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-column-gap: 8px;
			left: 0;
			position: fixed;
			width: 100%;
			z-index: 4;
		}
	}

	&__action {
		@include modules.gutter('margin-bottom', 0.25);
		@include modules.gutter('margin-top', 0.25);

		@include modules.media-query('phone') {
			align-items: center;
			justify-content: center;
		}
	}

	&__nav {
		// @include modules.gutter('padding-left');
		// @include modules.gutter('padding-right');
		align-items: center;
		background-color: modules.color_('background');
		border-bottom: 1px solid modules.color_('border', 'dark');
		display: none;
		height: modules.$header-height;
		justify-content: space-between;
		position: fixed;
		pointer-events: none;
		top: modules.$header-height;
		width: 100%;
		z-index: 2;

		@include modules.media-query('phone') {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			pointer-events: auto;
		}

		&-item {
			align-items: center;
			color: modules.color_('text', 'medium');
			display: flex;
			flex-direction: column;

			&:active,
			&:hover {
				text-decoration: none;
			}

			.material-icons {
				font-size: 20px;
			}

			&-label {
				letter-spacing: 0.5px;
				font-size: 13px;
				font-weight: 300;
				text-transform: uppercase;
			}
		}
	}

	&__complaint {
		white-space: pre-wrap;
	}

	&__symptom {
		@include modules.gutter('padding', 0.5);
		align-items: flex-start;
		border-bottom: 1px solid modules.color_('border');

	}

	.card__title {
		background-color: modules.color_('background');
		border-bottom: 1px solid modules.color_('border');
		border-top-left-radius: modules.$border-radius;
		border-top-right-radius: modules.$border-radius;
		position: sticky;
		top: 0px;
		z-index: 1;

		@include modules.media-query('phone') {
			top: modules.$header-height * 2;
		}

		@media print {
			position: relative;
			top: 0;
		}
		.print & {
			position: relative;
			top: 0;
		}
	}
}

.filler {
	height: 10000px;
}
</style>
