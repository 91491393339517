<template>
	<div class="checkin-toggle">
		<div class="checkin-toggle__description">
			<span class="checkin-toggle__client-name">{{ me.client.name }}</span><br />
			{{ me.location.address }} | Terminal {{ me.terminal }}
		</div>
		<button class="mdc-button mdc-button--unelevated" :class="{'mdc-button--disabled':!open}" @click="toggle">
			<span class="mdc-button__ripple"></span>
			<i class="material-icons mdc-button__icon" aria-hidden="true" v-html="open ? 'check_circle' : 'cancel'">cancel</i>
			<span class="mdc-button__label" v-html="open? 'Accepting Check-Ins' : 'NOT Accepting Check-Ins'">Accepting Check-Ins</span>
		</button>
		<!-- <switch-field v-model="openToggle" enabled-label="Accepting Check-Ins" disabled-label="NOT Accepting Check-Ins" @change="toggle" /> -->
	</div>
</template>

<script>
import SwitchField from '@/components/SwitchField'

export default {
	name: 'CheckinToggle',
	components: {
		SwitchField,
	},
	data: () => ({
		openToggle: false,
	}),
	computed: {
		me() {
			return this.$store.getters['user/me']
		},
		open() {
			return this.$store.getters['transfer/open']
		},
	},
	methods: {
		toggle() {
			if (this.open) {
				this.$store.dispatch('transfer/unsetAccepting', this.me)
			} else {
				this.$store.dispatch('transfer/setAccepting', this.me)
			}
		}
	},
	watch: {
		open: {
			immediate: true,
			handler(val) {
				this.openToggle = val
			},
		},
	}
}
</script>

<style scoped lang="scss">
.checkin-toggle {
	&__description {
		@include modules.fontSize(15px);
		margin-bottom: 0.5rem;
	}

	&__client-name {
		font-weight: 600;
		text-transform: uppercase;
	}
}
.mdc-button {
	&--disabled {
		color: rgba(0, 0, 0, 0.38);
		background-color: rgba(0, 0, 0, 0.12);
	}
}
</style>
