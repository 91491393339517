<template>
	<div class="list list--checkins list-checkins">
		<div class="list-checkins__header">
			<tab-bar
				class="list-checkins__nav"
				:items="statuses"
				@clicked="setStatus"
				>
				<template slot-scope="{ item, }" slot="item">
					<router-link
						class="tab-bar__item"
						:to="item.link"
						active-class="tab-tab__item--active"
					>
						{{ item.label }}
					</router-link>
				</template>
			</tab-bar>

			<form class="list-checkins__form" @submit.prevent.stop="submitForm(keyword)">
				<text-field label="Search..." v-model="keyword" type="search" @input="fieldChange" />
			</form>
		</div>

		<table-list
			:items="checkins"
			:loading="isLoading"
		>
			<template slot="header">
				<span
					:class="`checkins-header-item checkins-header-item--${key}`"
					:key="key"
					v-for="(col, key) in columns"
				>
					<component :is="orderBy[key] ? 'a' : 'span'" @click="sort(key)"> {{ col }}</component>
					<ion-icon :name="query.order == 'asc' ? `caret-up` : `caret-down`" v-if="orderBy[key] && orderBy[key] == query.orderBy"></ion-icon>
				</span>
			</template>

			<template slot="item" slot-scope="{ item }">
				<router-link
					:class="`checkins-item checkins-item__photo checkins-item--${item['status']}`"
					:to="{ name: 'checkin', params: { id: item.id } }"
				>
					<div
						class="list-checkins__photo"
						:style="{ backgroundImage: bgImg(item.photo) }"
						:data-initials="`${item.firstName} ${item.lastName}` | initials"
					>{{ item.photo }}</div>
				</router-link>
				<router-link
					:class="`checkins-item checkins-item__name checkins-item--${item['status']}`"
					:to="{ name: 'checkin', params: { id: item.id } }"
				>
					{{ item.firstName }} {{ item.lastName }}
				</router-link>
				<router-link
					:class="`checkins-item checkins-item__dob checkins-item--${item['status']}`"
					:to="{ name: 'checkin', params: { id: item.id } }"
				>
					{{ item.dob }}
				</router-link>
				<router-link
					:class="`checkins-item checkins-item__createdAt checkins-item--${item['status']}`"
					:to="{ name: 'checkin', params: { id: item.id } }"
				>
					{{ item.createdAt | date('p') }}<br />
					{{ item.createdAt | timeAgo }}
				</router-link>
				<router-link
					:class="`checkins-item checkins-item__status checkins-item--${item['status']}`"
					:to="{ name: 'checkin', params: { id: item.id } }"
				>
					<status-chip :status="item.status | enums('CheckinStatus', true)" />
				</router-link>
			</template>

			<template slot="footer" slot-scope="{ items }">
				<p>{{ items.length }} {{ 'Check-In' | pluralize(items.length) }}</p>
			</template>

		</table-list>
	</div>
</template>

<script>
import { format, parseISO, formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns'
import { get, debounce } from 'lodash'

import TabBar from '@/components/TabBar'
import TableList from '@/components/TableList'
import TextField from '@/components/TextField'
import StatusChip from '@/components/StatusChip'
import { CheckinStatus } from '@/lib/enums'

export default {
	name: 'ListCheckins',
	components: {
		StatusChip,
		TabBar,
		TableList,
		TextField,
	},
	props: {
		checkins: {
			type: Array,
			default: () =>([]),
		},
		checkins: {
			type: Array,
			default: () => ([]),
		},
	},
	data: () => ({
		columns: {
			profilePhoto: 'Photo',
			name: 'Name',
			dob: 'Birthdate',
			createdAt: 'Check-In Time',
			status: 'Status',
		},
		keyword: undefined,
		order: 'asc',
		statuses: [
			{
				label: 'All',
				link: { query: {
					status: null,
				}}
			},
			{
				label: 'New',
				link: {
					query: {
						status: CheckinStatus.New,
					},
				},
			},
			{
				label: 'Accepted',
				link: {
					query: {
						status: CheckinStatus.Accepted,
					},
				},
			},
			{
				label: 'Transferred',
				link: {
					query: {
						status: CheckinStatus.Transferred,
					},
				},
			},
		],
	}),
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		query() {
			return this.$route.query
		},
		orderBy() {
			let orderBy = {
				// nameEmail: 'firstName',
				// dateCreated: 'dateCreated',
				// organization: 'organization.name',
				// 'settings.salesforceId': 'salesforceId',
				// dateLogin: 'dateLogin',
				// status: 'status',
			}

			return orderBy
		},
	},
	methods: {
		transform(key, value) {
			switch(key) {
				case 'dateCreated':
					try {
						value = `${format(parseISO(value), 'PPp')}`
					} catch (error) {
						console.warn(error)
					}
					return value
				case 'createdAt':
					if (!value) return '(no login yet)'
					try {
						const dt = new Date(value)
						value = `${format(dt, 'p')}<br /><span title="${format(dt, 'Pp')}">${formatDistanceToNowStrict(dt, { addSuffix: true})}</span>`
					} catch (error) {
						console.warn(error)
					}
					return value
				// case 'status':
				// 	console.log({ value, })
				// 	return CheckinStatus[value]
				case 'checkinId':
					const checkin = this.checkins.find(c => c.checkinId == value)
					return checkin ? checkin.name : ``
			}
			return value
		},
		sort(key) {

		},
		bgImg(src) {
			if (!src) return ''
			return `url(data:image/jpg;base64,${src})`
		},
		status(status) {
			const obj = {}
			for (const k in CheckinStatus) {
				obj[CheckinStatus[k]] = k
			}
			return obj[status]
		},
		setStatus(idx) {
			this.statuses[idx].action()
		},
		submitForm(keyword) {
			console.log('submitFrm', {keyword})
			this.$router.push({ query: { keyword }, })
		},
		fieldChange: debounce(function (keyword) {
			this.$router.push({ query: { keyword, }})
			console.log('DEBOUNCE', { keyword,}, this)
		}, 150),
		get,
	}
}
</script>

<style scoped lang="scss">
.list-checkins {
	::v-deep .table-list {
		&__item,
		&__header {
			border-bottom: 1px solid modules.color_('border', 'light');
			display: grid;
			grid-template-columns: 110px minmax(100px, 1fr) repeat(2, minmax(100px, 1fr)) 0.75fr;

			@include modules.media-query('phone') {
				grid-template-columns: minmax(50%, 1fr) minmax(50%, 1fr);
			}
		}

		&__item {
			align-items: center;
		}

		.list-checkins__app-list {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}
	}

	&__header {
		display: grid;
		grid-template-columns: 1fr auto;
	}

	.checkins-header-item {
		@include modules.fontSize(14px);
		align-items: center;
		display: flex;
		font-weight: 600;
		padding: 1em 1.5em;

		@include modules.media-query('phone') {
			padding: 0.5em 1em;
		}
	}

	.checkins-item {
		color: modules.color_('text', 'dark');
		font-weight: 500;
		overflow-wrap: break-word;
		padding: 1.5em;
		text-decoration: none;

		@include modules.media-query('phone') {
			@include modules.fontSize(15px);
			padding: 0.5em 1em;
		}

		&--pending {
			&.checkins-item--status {
				color: modules.color_('alert', 'warn');
			}
		}

		&--removed {
			opacity: 0.7;

			&.checkins-item--status {
				color: modules.color_('alert', 'error')
			}
		}

		&__name {
			font-weight: 600;
		}

	}

	&__photo {
		background-color: darken(modules.color_('background', 'light'), 4);
		background-position: center center;
		background-size: cover;
		align-items: center;
		border-radius: modules.$border-radius;
		color: rgba(0, 0, 0, 0);
		display: flex;
		flex-direction: column;
		height: 90px;
		overflow: hidden;
		justify-content: center;
		width: 90px;

		@include modules.media-query('phone') {
			height: 70px;
			width: 70px;
		}

		&:empty {
			&:before {
				color: modules.color_('text', 'medium');
				content: 'person';
				font-family: 'Material Icons';
				font-feature-settings: 'liga';
				font-size: 48px;
			}

			&:after {
				color: modules.color_('text', 'medium');
				content: attr(data-initials);
				font-size: 14px;
				font-weight: 600;
				letter-spacing: 2px;
			}
		}
	}
}
</style>
