<template>
	<div class="partial partial--checkins checkins">
		<div class="partial__heading">
			<h1 class="partial__title">Check-Ins</h1>
		</div>
		<div class="partial__body">
			<card>
				<list-checkins :checkins="queryResults" />
			</card>
		</div>
	</div>
</template>

<script>
import Card from '@/components/Card'
import ListCheckins from '@/components/ListCheckins'
import Fuse from 'fuse.js'

export default {
	name: 'PartialCheckins',
	components: {
		Card,
		ListCheckins,
	},
	computed: {
		checkins() {
			return this.$store.getters['checkin/checkins']
		},
		queryResults() {
			let checkins = this.checkins || []
			if (!checkins.length) return checkins
			const { status, keyword } = this.$route.query
			if (status) {
				checkins = checkins.filter(r => r.status === status)
			}
			if (!keyword) return checkins.sort((a, b) => a.createdAt - b.createdAt)

			const fuseOptions = {
				threshold: 0.2,
				includeMatches: true,
				findAllMatches: true,
				includeScore: true,
				tokenize: true,
				keys: [
					'firstName',
					'lastName',
					'email',
				],
			}

			const fuse = new Fuse(checkins, fuseOptions)
			const result = fuse.search(keyword)
			result.sort((a, b) => a.score - b.score)
			const results = (result).map((res) => res.item)
			return results
		},

	},
	mounted() {
		this.$store.dispatch('checkin/loadCheckins')
	},
}
</script>

<style scoped lang="scss">

</style>
