<template>
	<div class="checkin-fields">
		<div
			class="checkin-field"
			:class="`checkin-field--${field.type} checkin-field--${field.name}`"
			:key="key"
			v-for="(field, key) in checkinFields"
			@dblclick.prevent.stop="copyField(field)"
			>
			<div class="checkin-field__icon">
				<i class="material-icons">{{ field.icon }}</i>
			</div>
			<div class="checkin-field__label">
				{{ field.label }}
			</div>
			<div class="checkin-field__value" v-html="field.value" v-if="field.type !== 'header'"></div>
		</div>
		<input type="hidden" id="copy_input" />
	</div>
</template>

<script>
import { formField } from '@/lib/utils'
import { v4 } from 'uuid'

export default {
	name: 'CheckinFields',
	data: () => ({
		copyInput: document.querySelector('#copy_input')
	}),
	props: {
		checkin: {
			type: Object,
			default: () => ({}),
		},
		fields: {
			type: Array,
			default: () => undefined,
		},
		source: {
			type: Array,
			default: () => undefined,
		},
	},
	computed: {
		checkinFields() {
			let fields = []
			this.fields.forEach((name) => {
				fields.push(formField(name, this.checkin[name], this.checkin, this.source))
			})

			return fields
		},
	},
	methods: {
		formField(name, value) {
			return formField(name, value)
		},
		copyField(field) {
			if ( (field.type == 'input' || field.type == 'dropdown') && this.copyInput) {
				this.copyInput.value = field.value
				this.copyInput.select()

				let copied
				try {
					copied = document.execCommand('copy')
				} catch (error) { }

				if (copied) {
					this.$notice(`"${field.value}" Copied to clipboard!`, null, { id: v4() })
				}
			}
		}
	}
}
</script>

<style scoped lang="scss">
.checkin-field {
	$field: &;
	@include modules.gutter('padding', 0.5);
	align-items: flex-start;
	border-bottom: 1px solid modules.color_('border');
	display: grid;
	grid-template-columns: 32px 1fr 1.5fr;
	grid-gap: 0 1em;
	// display: flex;
	// flex-direction: row;
	justify-content: space-between;
	line-height: 28px;

	@include modules.media-query('phone') {
		grid-template-columns: 26px 1fr;
	}

	@media print {
		@include modules.gutter('padding', 0.25);
		grid-template-columns: 0px 1fr 1fr;
	}

	&--licensePhoto,
	&--insurancePhotoFront,
	&--insurancePhotoBack,
	&--covidCardFront,
	&--covidCardBack {
		@media print {
			grid-template-columns: 1fr;

			#{$field}__label {
				display: none;
			}

			#{$field}__value {
				::v-deep .image {
					max-width: 650px !important;
				}
			}
		}
	}

	&--historyMedical {
		grid-template-columns: 48px 1fr;

		#{$field}__label {
			display: none;
		}

		#{$field}__value {
			column-count: 2;

			@include modules.media-query('phone') {
				column-count: 1;
			}
		}


		@media print {
			grid-template-columns: 1fr;
			#{$field}__icon,
			#{$field}__label {
				display: none !important;
			}
		}
	}

	&--header {
		grid-template-columns: 0px 1fr;

		#{$field}__label {
			font-size: 18px;
			font-weight: 500;
			padding-top: 0.5rem;
		}
	}

	&__icon {
		.material-icons {
			color: modules.color_('text', 'medium');
			font-size: 22px;
			transform: translateY(2px);
		}

		@media print {
			overflow: hidden;
			width: 0px;
		}
	}

	&__label {
		color: modules.color_('text', 'medium');
		font-weight: 600;
		flex: 1 0 auto;
		line-height: 1.3;

		@media print {
			line-height: inherit;
		}

		@include modules.media-query('phone') {
			grid-row-start: 2;
			grid-column: 2 / 2;
			font-weight: 300;
		}

	}

	&__value {
		white-space: pre;
		&:empty {
			&:before {
				color: modules.color_('text','light');
				content: '(empty)';
				display: inline-block;
				font-size: 15px;
			}
		}

		@include modules.media-query('phone') {
			grid-row-start: 1;
			grid-column: 2 / 2;
			// grid-column-start: 2;
		}
	}

	&--image {
		#{$field}__value {
			::v-deep .image {
				border: 1px solid modules.color_('border');
				border-radius: modules.$border-radius;
				max-width: 320px;

				@include modules.media-query('phone') {
					max-width: 200px;
				}
			}
		}
	}

	&--checkbox {
		grid-template-columns: 32px 1fr 1.5fr;

		@media print {
			grid-template-columns: 0px 1fr 1fr;

			#{$field}__value {
				// grid-column-start: 1;
			}

			#{$field}__label {
				// grid-row-start: 1;
				// grid-column-start: 2;
			}

		}

		@include modules.media-query('phone') {
			grid-template-columns: 0px 1fr auto;

			#{$field}__value {
				grid-row-start: 1;
				grid-column: 3;
			}

			#{$field}__label {
				grid-row-start: 1;
				grid-column: 2;
			}
		}

	}
}
</style>
