<template>
	<modal
		:open="open"
		:sticky="sticky"
		title="Incoming Check-In">
		<template slot="content">
			<h4 v-html="statusText"></h4>
			<loading-line :visible="sticky"></loading-line>
		</template>
		<template slot="actions" slot-scope="{ dialog }">
			<mdc-button class="mdc-button__dialog modal-transfer__button modal-transfer__button--cancel" @click="cancel()" v-if="sticky">
				<span class="mdc-button__label">CANCEL</span>
			</mdc-button>
			<mdc-button  class="mdc-button mdc-button__dialog" @click="dialog.close()" v-if="!sticky">
				<span class="mdc-button__label">Close</span>
			</mdc-button>
		</template>
	</modal>
</template>

<script>
import MdcButton from '@/components/MdcButton'
import Modal from '@/components/Modal'
import LoadingLine from '@/components/LoadingLine'
import { TransferStatus } from '@/lib/enums'

export default {
	name: 'ModalCheckinStatus',
	components: {
		LoadingLine,
		MdcButton,
		Modal,
	},
	computed: {
		status() {
			return this.$store.getters['transfer/status']
		},
		open() {
			return Boolean(this.status)
		},
		sticky() {
			let sticky = true
			switch(this.status) {
				case TransferStatus.Fail:
				case TransferStatus.Success:
				case TransferStatus.Cancelled:
					sticky = false
					break
			}

			return sticky
		},
		statusText() {
			let status = ''

			switch(this.status) {
				case TransferStatus.Incoming:
					status = 'Patient Check-In Incoming...'
					break
				case TransferStatus.Connecting:
					status = 'Patient Check-In Connecting...'
					break
				case TransferStatus.Fail:
					status = 'Patient Check-In FAILED! Please ask patient to try again.'
					break
				case TransferStatus.Transferring:
					status = 'Patient Check-In Transferring...'
					break
				case TransferStatus.Transferred:
					status = 'Patient Check-In Transferred!'
					break
				case TransferStatus.Success:
					status = 'Patient Check-In Success!'
					break
				case TransferStatus.Cancelled:
					status = 'Patient Check-In Cancelled'
					break
			}
			return status
		},
	},
	methods: {
		cancel() {
			this.$store.dispatch('transfer/cancelTransfer')
		},
	},
}
</script>

<style scoped lang="scss">

</style>
