<template>
	<footer class="main-footer">
		<checkin-toggle  />
		<button
			class="mdc-icon-button material-icons"
			aria-label="Show Check In Code"
			title="Show Check In Code"
			@click="codeToggle = true"
			>
			<span class="mdc-icon-button__ripple"></span>
			border_outer
		</button>
		<modal-checkin-code :open="codeToggle" @close="codeToggle = false" />
	</footer>
</template>

<script>
import CheckinToggle from '@/components/CheckinToggle'
import ModalCheckinCode from '@/components/ModalCheckinCode'

export default {
	name: 'MainFooter',
	components: {
		CheckinToggle,
		ModalCheckinCode,
	},
	data: () => ({
		codeToggle: false,
	}),
}
</script>

<style scoped lang="scss">
@use "@material/icon-button/styles";
// @include icon-button.core-styles;
.main-footer {
	@include modules.gutter('padding-left');
	@include modules.gutter('padding-right');
	border-top: 1px solid rgba(255, 255, 255, 0.25);
	background-color: rgba(255, 255, 255, 0.15);
	display: flex;
	justify-content: space-between;
	align-items: center;
	backdrop-filter: blur(1px);

	.mdc-dialog-scroll-lock & {
		backdrop-filter: none;
	}

	@media print {
		display: none;
	}

	.mdc-icon-button {
		color: modules.color_('primary');
	}
	.mdc-button__icon {
		font-size: 32px;
	}
}
</style>
