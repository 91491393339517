<template>
	<header class="main-header">
		<loading-line class="main-header__loading" v-if="isLoading" />
		<router-link :to="{ name: 'main' }" class="main-header__logo">
			<img src="@/assets/qp_icon--white.svg" alt="Quick Patient" class="main-header__logo--icon hide-tablet" />
			<img src="@/assets/qp_logo--white-text--shadow.svg" alt="Quick Patient" class="main-header__logo--text hide-phone" />
		</router-link>
		<div class="main-header__actions">
			<user-nav />
		</div>
	</header>
</template>

<script>
import LoadingLine from '@/components/LoadingLine'
import UserNav from '@/components/UserNav'

export default {
	name: 'MainHeader',
	components: {
		LoadingLine,
		UserNav,
	},
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
	},
}
</script>

<style scoped lang="scss">
.main-header {
	@include modules.gutter('padding-bottom', 0.5);
	@include modules.gutter('padding-left', 1);
	@include modules.gutter('padding-right', 1);
	@include modules.gutter('padding-top', 0.5);
	align-items: center;
	background-color: modules.color_('primary');
	border-bottom: 1px solid darken(modules.color_('primary'), 5);
	color: modules.color_('primary', 'text');
	display: flex;
	height: modules.$header-height;
	justify-content: space-between;

	&__logo {

		&--text {
			max-width: 100%;
			transform: translateY(6px);
		}

		&--small {
			width: 48px;
		}

		&--icon {
			width: 42px;
		}
	}

	&__loading {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}

	@media print {
		display: none;
	}
	.print & {
		display: none;
	}
}
</style>
