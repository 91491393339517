<template>
	<div class="switch-field">
		<button :id="id" class="mdc-switch" :class="{'mdc-switch--selected': active,}" type="button" role="switch" :aria-checked="active" @click.prevent="toggle">
			<div class="mdc-switch__track"></div>
			<div class="mdc-switch__handle-track">
				<div class="mdc-switch__handle">
				<div class="mdc-switch__shadow">
					<div class="mdc-elevation-overlay"></div>
				</div>
				<div class="mdc-switch__ripple"></div>
				<div class="mdc-switch__icons">
					<svg class="mdc-switch__icon mdc-switch__icon--on" viewBox="0 0 24 24">
						<path d="M19.69,5.23L8.96,15.96l-4.23-4.23L2.96,13.5l6,6L21.46,7L19.69,5.23z" />
					</svg>
					<svg class="mdc-switch__icon mdc-switch__icon--off" viewBox="0 0 24 24">
						<path d="M20 13H4v-2h16v2z" />
					</svg>
				</div>
				</div>
			</div>
		</button>
		<label :for="id">{{ attrs.value ? enabledLabel ? enabledLabel : label : disabledLabel ? disabledLabel : label }}</label>
	</div>
</template>

<script>
import { MDCSwitch } from '@material/switch'
import { v4 } from 'uuid'

export default {
	name: 'SwitchField',
	props: {
		label: String,
		disabledLabel: String,
		enabledLabel: String,
		id: {
			type: String,
			default: v4(),
		},
		name: String,
	},
	data: () => ({
		switch: undefined,
	}),
	computed: {
		attrs() {
			return this.$attrs
		},
		listeners() {
			return {
				...this.$listeners,
				input: event => this.input(event),
				change: event => this.input(event),
			}
		},
		active() {
			let _active = Boolean(this.attrs.value)
			if (this.switch) {
				this.switch.selected = _active
			}
			return _active
		},
	},
	mounted() {
		this.switch = new MDCSwitch(this.$el.querySelector('.mdc-switch'))
		this.switch.selected = Boolean(this.$attrs.value)
	},
	methods: {
		toggle() {
			this.$emit('change', !this.switch.selected)
		},
		input(evt) {
			this.$emit('input', evt)
		},
	},
}
</script>

<style scoped lang="scss">
@use '@material/switch/styles';

.switch-field {
	label {
		margin-left: 0.5rem;
	}
}
.mdc-switch {
	$switch: &;

	&:enabled {
		#{$switch}__track {
			&:after {
				background: lighten(modules.color_('primary'), 30);
			}
		}
		&:hover {
			&:not(:focus) {
				&:not(:active) {
					#{$switch}__track {
						&:after {
							background: lighten(modules.color_('primary'), 25);
						}
					}
				}
			}
		}
		&:active {
			#{$switch}__track {
				&:after {
					background: lighten(modules.color_('primary'), 25);
				}
			}
		}
		&:focus {
			&:not(:active) {
				#{$switch}__track {
					&:after {
						background: lighten(modules.color_('primary'), 25);
					}
				}
			}
		}

	}

	&#{$switch}--selected {
		&:enabled {
			&:hover {
				&:not(:focus) {
					&:not(:active) {
						#{$switch}__handle {
							&::after {
								background: darken(modules.color_('primary'), 5);
							}
						}
					}
				}
			}
			&:active {
				#{$switch}__handle {
					&::after {
						background: darken(modules.color_('primary'), 5);
					}
				}
			}
			&:focus {
				&:not(:active) {
					#{$switch}__handle {
						&::after {
							background: darken(modules.color_('primary'), 5);
						}
					}
				}
			}
		}
	}

}
</style>
