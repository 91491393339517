<template>
	<div class="checkin-fields-list__items" :class="`checkin-fields-list--${matchField.name}`">
		<div class="checkin-fields-list__items-header">
			<div class="checkin-fields-list__items-icon">
				<i class="material-icons" v-if="matchField.icon">{{ matchField.icon }}</i>
			</div>
			<div :key="key" v-for="(column, key) in columns">
				{{ column.label }}
			</div>
		</div>
		<div class="checkin-fields-list__items-row" :key="key" v-for="(row, key) in items">
			<div class="checkin-fields-list__items-icon"></div>
			<div class="checkin-fields-list__items-cell" :key="k" v-for="(name, k) in row">
				{{ name }}
			</div>
		</div>
	</div>
</template>

<script>
import { formField } from '@/lib/utils'

export default {
	name: 'CheckinFieldsList',
	props: {
		field: String,
		checkin: Object,
		value: Array,
		fields: {
			type:Array,
			default: () => ([]),
		},
	},
	computed: {
		matchField() {
			if (this.field) {
				let matchField = formField(this.field, this.checkin[this.field], this.checkin, this.fields)
				if (matchField) {
					return matchField
				}
			}
			return { icon: null }
		},
		columns() {
			let columns = []
			if (!this.checkin) return columns
			let matchField = formField(this.field, this.checkin[this.field] || {}, this.checkin, this.fields)
			if (matchField && matchField.value) {
				return matchField.value
			}

			return columns
		},
		items() {
			let rows = []
			if (this.checkin && this.field && this.checkin[this.field]) {
				for (const field of this.checkin[this.field]) {
					const row = []
					for (const column of this.columns) {
						if (field[column.name]) {
							if (column.children) {
								if (column.children[field[column.name]]) {
									row.push(column.children[field[column.name]])
								}
							} else {
								let v = field[column.name]
								try {
									let p = JSON.parse(field[column.name])
									v = `${p.label} (${p.value})`
								} catch (error) {

								}
								row.push(v)
							}
						} else {
							let _key = Object.keys(column)[0]
							row.push(field[_key])
						}
					}
					rows.push(row)
				}
			}
			return rows
		},
	}
}
</script>

<style scoped lang="scss">
.checkin-fields-list {
	$list: &;

	&__items {
		&-header,
		&-row {
			@include modules.gutter('padding', 0.5);
			border-bottom: 1px solid modules.color_('border');
			display: grid;
			grid-template-columns: 48px 1.5fr 2fr;

			@media print {
				grid-template-columns: 1fr 2fr;
			}
			.print & {
				grid-template-columns: 1fr 2fr;
			}
		}

		&-cell {
			padding-left: 0.5rem;
		}

		&-header {
			border-bottom: 1px dashed modules.color_('border', 'dark');
			color: modules.color_('text', 'medium');
			font-weight: 500;
			letter-spacing: 0.5px;
			padding-bottom: 0.25rem;
		}

		&-icon {
			@media print {
				display: none;
			}
			.print & {
				display: none;
			}

			.material-icons {
				color: modules.color_('text', 'medium');
				font-size: 22px;
				transform: translateY(1px);
			}
		}
	}

	&--historyMedications {
		#{$list}__items {
			&-header,
			&-row {
				grid-template-columns: 48px auto;
				.print & {
					grid-template-columns: auto;
				}
			}
		}
	}
}
</style>
